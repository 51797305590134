<template>
  <div class="comment-thread" :class="{ active: showCommentThread }" @click.stop>
    <div v-if="commentsThread && commentsThread.length" class="thread-zone">
      <div v-for="(item, index) in commentsThread" :key="index" class="comment">
        <header>
          <div v-if="item.user" class="left">
            <div class="profile">
              <p v-if="item.user.displayName">{{ item.user.displayName[0] + item.user.displayName[1] }}</p>
              <p v-else>{{ item.user.email[0] }}</p>
            </div>
            <p class="name">{{ item.user.displayName }}</p>
          </div>
          <div v-else class="left">
            <div class="profile">
              <p>{{ selectedClient.name.trim()[0] + selectedClient.name.trim()[1] }}</p>
            </div>
            <p class="name">{{ selectedClient.name.trim() }}</p>
          </div>
          <div v-if="index === 0 && user" class="right">
            <a class="resolve-comment" href="#" @click.prevent.stop="emitWillResolveCurrentMarker()">Resolve</a>
          </div>
        </header>
        <p class="comment-text">{{ item.content }}</p>
      </div>
    </div>
    <div class="reply-zone">
      <div class="line"></div>
      <div class="comment reply">
        <header>
          <div v-if="user" class="left">
            <div class="profile">
              <p v-if="user.displayName">{{ user.displayName[0] + user.displayName[1] }}</p>
              <p v-else>{{ user.email[0] }}</p>
            </div>
          </div>
          <div v-else class="left">
            <div class="profile">
              <p>{{ client.name[0] + client.name[1] }}</p>
            </div>
          </div>
          <div v-if="(!commentsThread || !commentsThread.length) && user" class="right">&nbsp;</div>
        </header>
        <form action="" @submit.prevent.stop="handleCommentCreation()">
          <textarea v-model="userComment" class="write-comment" placeholder="Write a comment" required></textarea>
          <input type="submit" class="send-comment" value="Post" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CommentsThreadDB from '@/firebase/commentsThread-db'

export default {
  props: {
    showCommentThread: {
      type: Boolean,
      default: false,
    },
    commentsThread: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: null,
    },
    client: {
      type: Object,
      default: null,
    },
    selectedClient: {
      type: Object,
      default: null,
    },
    currentMarker: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userComment: '',
    }
  },
  mounted() {
    this.userComment = ''
  },
  methods: {
    async handleCommentCreation() {
      if (!this.userComment) {
        return
      }

      const CommentsThreadDBRef = new CommentsThreadDB()
      let commentsThread = await CommentsThreadDBRef.read(this.currentMarker.id)

      if (!commentsThread) {
        commentsThread = await CommentsThreadDBRef.create({ id: this.currentMarker.id, comments: [] }, this.currentMarker.id)
      }

      const currentComments = commentsThread.comments.length ? commentsThread.comments : []

      currentComments.push({
        user: this.user,
        content: this.userComment,
      })

      commentsThread.comments = currentComments

      await CommentsThreadDBRef.update(commentsThread)

      this.userComment = ''

      this.emitRefreshCurrentCommentsThread()
    },
    emitWillResolveCurrentMarker() {
      this.$emit('emitWillResolveCurrentMarker')
    },
    emitRefreshCurrentCommentsThread() {
      this.$emit('emitRefreshCurrentCommentsThread')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/comments.scss';
</style>
