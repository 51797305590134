<template>
  <div v-if="isLoading" class="loader-wrap">
    <img src="@/assets/img/pastille.svg" alt="Showadz" class="pastille" />
    <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#4628FF">
      <g transform="translate(1 1)" stroke-width="1" fill="none" fill-rule="evenodd">
        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
        </path>
      </g>
    </svg>
    <p class="loading-label">Loading your ads</p>
  </div>

  <div v-else class="google-ads-main-wrap">
    <!-- Top/Search Bar -->
    <header class="google-ads-header">
      <div class="left">
        <img src="@/assets/img/google-logo.png" alt="Google logo" class="logo" />
        <input type="text" value="Keywords" class="search" />
      </div>
      <div class="right">
        <router-link
          v-if="$route.name === 'google'"
          class="import-google-ads"
          :to="{
            name: 'CreateGoogle',
            params: { clientId: $route.params.clientId, campaignId: $route.params.campaignId },
          }"
        >
          <p>Import ads</p>
        </router-link>
        <img src="@/assets/img/google-dots.jpg" alt="Google dots" class="dots" />
        <img src="@/assets/img/google-profile.jpg" alt="Showadz" class="profile" />
      </div>
    </header>

    <!-- Page content -->
    <div class="content-ads-wrap">
      <!-- Show Campaing Preview -->
      <div class="ads-wrap">
        <GoogleAdzWrap
          v-for="campaignObj in campaigns"
          :key="campaignObj.name"
          :campaign-obj="campaignObj"
          :selected-adz-campaign="selectedAdzCampaign"
          :selected-adz-group="selectedAdzGroup"
          :selected-adz-group-slug="selectedAdzGroupSlug"
          :default-all-campaign="defaultAllCampaign"
          :comments-toggle="commentsToggle"
          :user-comments-history="userCommentsHistory"
          :handle-create-marker="handleCreateMarker"
          :handle-marker-click="handleMarkerClick"
        />
      </div>
      <!-- / Show Campaing Preview -->

      <!-- Campaing Filter menu -->
      <aside v-if="campaigns && campaigns.length" class="campaings-wrap">
        <!-- Campaigns -->
        <div class="section-title show">
          <svg width="18" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 1.984V10l2.484-1.5L8.016 10V1.984H3zM15 .016c.531 0 1 .203 1.406.609.407.375.61.828.61 1.36v16.03c0 .532-.203 1-.61 1.407a2.011 2.011 0 0 1-1.406.562H3c-.531 0-1-.187-1.406-.562-.407-.406-.61-.875-.61-1.406V1.984c0-.53.203-.984.61-1.359C2 .219 2.469.015 3 .015h12z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient id="paint0_linear" x1="-.226" y1="9.601" x2="19.133" y2="9.601" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2E7DF2" />
                <stop offset="1" stop-color="#8A23F1" />
              </linearGradient>
            </defs>
          </svg>
          <p>Campaigns</p>
        </div>
        <div
          v-for="item in campaigns"
          :key="item.name"
          class="data-name"
          :class="{
            actif: selectedAdzCampaign && item.name === selectedAdzCampaign.name,
            off: selectedAdzCampaign && item.name !== selectedAdzCampaign.name,
          }"
          @click.prevent="selectAdzCampaign(item)"
        >
          <p>{{ item.name }}</p>
          <svg v-if="selectedAdzCampaign && item.name === selectedAdzCampaign.name" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.698 3.9a1.103 1.103 0 0 0-1.56 0l-2.34 2.34L5.46 3.9A1.103 1.103 0 1 0 3.9 5.46l2.34 2.339-2.34 2.34a1.103 1.103 0 1 0 1.56 1.56l2.34-2.34 2.34 2.34a1.103 1.103 0 0 0 1.56-1.56l-2.34-2.34 2.34-2.34c.43-.43.43-1.129 0-1.56z"
              fill="#637082"
            />
          </svg>
          <svg v-else width="6" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.056 10c-.272 0-.532-.105-.74-.305a1.068 1.068 0 0 1 0-1.494L3.475 5 .305 1.802a1.068 1.068 0 0 1 0-1.494 1.045 1.045 0 0 1 1.481 0l3.912 3.946c.198.2.302.463.302.747s-.115.547-.302.747L1.796 9.695c-.208.2-.48.305-.74.305z"
              fill="#637082"
            />
          </svg>
        </div>

        <!-- Adz Groups -->
        <div class="section-title" :class="{ show: selectedAdzCampaign }">
          <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.578 5.39c.406.407.875.61 1.406.61.532 0 1-.203 1.407-.61.406-.406.609-.874.609-1.406 0-.53-.203-.984-.61-1.359-.406-.406-.874-.61-1.406-.61-.53 0-1 .204-1.406.61a1.852 1.852 0 0 0-.562 1.36c0 .53.187 1 .562 1.406zM17.016 0c.28 0 .515.094.703.281A.954.954 0 0 1 18 .984v6c0 .282-.094.532-.281.75a.954.954 0 0 1-.703.282H.984a.954.954 0 0 1-.703-.282A1.12 1.12 0 0 1 0 6.984v-6A.96.96 0 0 1 .281.281.954.954 0 0 1 .984 0h16.032zM2.578 15.422a2.01 2.01 0 0 0 1.406.562c.532 0 1-.187 1.407-.562.406-.406.609-.875.609-1.406 0-.532-.203-1-.61-1.407-.406-.406-.874-.609-1.406-.609-.53 0-1 .203-1.406.61a2.011 2.011 0 0 0-.562 1.406c0 .53.187 1 .562 1.406zm14.438-5.438c.28 0 .515.11.703.329a.954.954 0 0 1 .281.703v6a.955.955 0 0 1-.281.703.955.955 0 0 1-.703.281H.984a.954.954 0 0 1-.703-.281.954.954 0 0 1-.281-.703v-6c0-.282.094-.516.281-.704a.888.888 0 0 1 .703-.328h16.032z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient id="paint0_linear" x1="-1.358" y1="8.64" x2="20.377" y2="8.64" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2E7DF2" />
                <stop offset="1" stop-color="#8A23F1" />
              </linearGradient>
            </defs>
          </svg>
          <p>Ad groups</p>
        </div>

        <div v-if="selectedAdzCampaign">
          <div
            v-for="(item, index) in selectedAdzCampaign.adGroups"
            :key="index"
            class="data-name"
            :class="{
              actif: selectedAdzGroup && item === selectedAdzGroup,
              off: selectedAdzGroup && item !== selectedAdzGroup,
            }"
            @click.prevent="selectAdzGroup(item)"
          >
            <p>{{ item }}</p>
            <svg v-if="selectedAdzGroup && item === selectedAdzGroup" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.698 3.9a1.103 1.103 0 0 0-1.56 0l-2.34 2.34L5.46 3.9A1.103 1.103 0 1 0 3.9 5.46l2.34 2.339-2.34 2.34a1.103 1.103 0 1 0 1.56 1.56l2.34-2.34 2.34 2.34a1.103 1.103 0 0 0 1.56-1.56l-2.34-2.34 2.34-2.34c.43-.43.43-1.129 0-1.56z"
                fill="#637082"
              />
            </svg>
            <svg v-else width="6" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.056 10c-.272 0-.532-.105-.74-.305a1.068 1.068 0 0 1 0-1.494L3.475 5 .305 1.802a1.068 1.068 0 0 1 0-1.494 1.045 1.045 0 0 1 1.481 0l3.912 3.946c.198.2.302.463.302.747s-.115.547-.302.747L1.796 9.695c-.208.2-.48.305-.74.305z"
                fill="#637082"
              />
            </svg>
          </div>
        </div>

        <!-- <div v-else>
          <div
            v-for="(item, index) in agregateAdgroups"
            :key="index"
            class="data-name"
            :class="{
              actif: selectedAdzGroup && item === selectedAdzGroup,
              off: selectedAdzGroup && item !== selectedAdzGroup
            }"
            @click.prevent="selectAdzGroup(item)"
          >
            <p>{{ item }}</p>
            <svg
              v-if="selectedAdzGroup && item === selectedAdzGroup"
              width="16"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.698 3.9a1.103 1.103 0 0 0-1.56 0l-2.34 2.34L5.46 3.9A1.103 1.103 0 1 0 3.9 5.46l2.34 2.339-2.34 2.34a1.103 1.103 0 1 0 1.56 1.56l2.34-2.34 2.34 2.34a1.103 1.103 0 0 0 1.56-1.56l-2.34-2.34 2.34-2.34c.43-.43.43-1.129 0-1.56z"
                fill="#637082"
              />
            </svg>
            <svg v-else width="6" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.056 10c-.272 0-.532-.105-.74-.305a1.068 1.068 0 0 1 0-1.494L3.475 5 .305 1.802a1.068 1.068 0 0 1 0-1.494 1.045 1.045 0 0 1 1.481 0l3.912 3.946c.198.2.302.463.302.747s-.115.547-.302.747L1.796 9.695c-.208.2-.48.305-.74.305z"
                fill="#637082"
              />
            </svg>
          </div>
        </div> -->

        <!-- Keywords -->
        <div class="section-title" :class="{ show: selectedAdzCampaign && selectedAdzGroupSlug && selectedAdzCampaign.keywordsAndType }">
          <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.938 16.516h2.109l-5.11-13.032H9.063l-5.11 13.032h2.11l1.125-3h5.625l1.124 3zm4.078-16.5c.53 0 .984.203 1.359.609.406.375.61.828.61 1.36v16.03c0 .532-.204 1-.61 1.407a1.852 1.852 0 0 1-1.36.562H1.986c-.532 0-1-.187-1.407-.562a2.012 2.012 0 0 1-.562-1.406V1.984c0-.53.187-.984.562-1.359.406-.406.875-.61 1.406-.61h16.032zM7.938 11.5L10 5.969l2.063 5.531H7.936z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient id="paint0_linear" x1="-1.491" y1="9.601" x2="22.622" y2="9.601" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2E7DF2" />
                <stop offset="1" stop-color="#8A23F1" />
              </linearGradient>
            </defs>
          </svg>
          <p>Keywords</p>
        </div>

        <div v-if="selectedAdzCampaign && selectedAdzGroupSlug && selectedAdzCampaign.keywordsAndType" class="keywords-wrap">
          <div
            v-for="(item, index) in selectedAdzCampaign.keywordsAndType[selectedAdzGroupSlug]"
            :key="index"
            :class="['keyword', item.type.replace(/ /g, '-')]"
          >
            <p>{{ item.name }}</p>
          </div>
        </div>
        <!-- <div v-else class="keywords-wrap">
          <div
            v-for="(item, index) in agregateKeywordsAndType"
            :key="index"
            :class="['keyword', item.type.replace(/ /g, '-')]"
          >
            <p>{{ item.name }}</p>
          </div>
        </div> -->

        <div
          v-if="selectedAdzCampaign && selectedAdzGroupSlug && selectedAdzCampaign.keywords && !selectedAdzCampaign.keywordsAndType"
          class="keywords-wrap"
        >
          <div v-for="(item, index) in selectedAdzCampaign.keywords[selectedAdzGroupSlug]" :key="index" class="keyword">
            <p>{{ item }}</p>
          </div>
        </div>
        <div v-else-if="!agregateKeywordsAndType" class="keywords-wrap">
          <div v-for="(item, index) in agregateKeywords" :key="index" class="keyword">
            <p>{{ item }}</p>
          </div>
        </div>
      </aside>

      <!-- Edit Panel -->
      <!-- <div class="edit-google-ad-panel">
        <img src="@/assets/img/close.svg" alt="close" class="close" />
        
        <form action="" class="edit-google-ad-form">

          <p class="section-title first">Ad</p>

          <div class="input-label-wrap">
            <input type="text" class="label-focus" />
            <p class="label">Final URL</p>
          </div>

          <div class="input-label-wrap half">
            <input type="text" class="label-focus" />
            <p class="label">Path 1</p>
          </div>

          <div class="input-label-wrap half">
            <input type="text" class="label-focus" />
            <p class="label">Path 2</p>
          </div>

          <div class="field-wrap">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Headline 1</p>
            </div>
            <p class="counter">30</p>
          </div>
          
          <div class="field-wrap">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Headline 2</p>
            </div>
            <p class="counter">30</p>
          </div>

          <div class="field-wrap">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Headline 3</p>
            </div>
            <p class="counter">30</p>
          </div>

          <div class="field-wrap">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 1</p>
            </div>
            <p class="counter">90</p>
          </div>

          <div class="field-wrap">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 2</p>
            </div>
            <p class="counter">90</p>
          </div>

          <p class="section-title">Sitelinks 1</p>

          <div class="field-wrap">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Title</p>
            </div>
            <p class="counter">25</p>
          </div>

          <div class="field-wrap half">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 1</p>
            </div>
            <p class="counter">35</p>
          </div>

          <div class="field-wrap half">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 2</p>
            </div>
            <p class="counter">35</p>
          </div>

          <p class="section-title">Sitelinks 2</p>

          <div class="field-wrap">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Title</p>
            </div>
            <p class="counter">25</p>
          </div>

          <div class="field-wrap half">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 1</p>
            </div>
            <p class="counter">35</p>
          </div>

          <div class="field-wrap half">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 2</p>
            </div>
            <p class="counter">35</p>
          </div>

          <p class="section-title">Sitelinks 3</p>

          <div class="field-wrap">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Title</p>
            </div>
            <p class="counter">25</p>
          </div>

          <div class="field-wrap half">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 1</p>
            </div>
            <p class="counter">35</p>
          </div>

          <div class="field-wrap half">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 2</p>
            </div>
            <p class="counter">35</p>
          </div>

          <p class="section-title">Sitelinks 4</p>

          <div class="field-wrap">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Title</p>
            </div>
            <p class="counter">25</p>
          </div>

          <div class="field-wrap half">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 1</p>
            </div>
            <p class="counter">35</p>
          </div>

          <div class="field-wrap half">
            <div class="input-label-wrap">
              <input type="text" class="label-focus" />
              <p class="label">Description 2</p>
            </div>
            <p class="counter">35</p>
          </div>

        </form>

      </div> -->
    </div>

    <!-- Comments thread Popup -->
    <CommentsBoxPopup
      v-if="selectedClient"
      :show-comment-thread="showCommentThread"
      :comments-thread="commentsThread"
      :user="user"
      :client="selectedClient"
      :selected-client="selectedClient"
      :current-marker="currentMarker"
      @emitWillResolveCurrentMarker="willResolveCurrentMarker"
      @emitRefreshCurrentCommentsThread="refreshCurrentCommentsThread"
      @click.stop
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import { functions } from '@/firebase/init'
import { getPercentFromZone, positionCommentBox } from '@/misc/helpers'
import AccountClientCampaignGoogleAdzDB from '@/firebase/account-client-campaign-googleAdz-db'
import ShareLinksGoogleDB from '@/firebase/shareLinksGoogle-db'
import CommentsThreadDB from '@/firebase/commentsThread-db'

import CommentsBoxPopup from '@/components/comments/CommentsBoxPopup'
import GoogleAdzWrap from '@/components/google/GoogleAdzWrap'

export default {
  components: {
    CommentsBoxPopup,
    GoogleAdzWrap,
  },
  head: function () {
    return {
      title: {
        inner: 'Preview Google ads',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: 'Showadz' },
        {
          name: 'description',
          content: `Google ads preview for approbation`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: 'Showadz' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Google ads preview for approbation`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Showadz' },
        { property: 'og:site_name', content: 'Showadz' },
        { property: 'og:type', content: `Website` },
        {
          property: 'og:description',
          content: `Google ads preview for approbation`,
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/og-google-ads.jpg?alt=media&token=9fd1bb68-dd94-4e53-b695-af61364e575f',
        },
        {
          name: 'twitter:image',
          content:
            'https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/og-google-ads.jpg?alt=media&token=9fd1bb68-dd94-4e53-b695-af61364e575f',
        },
      ],
    }
  },
  data() {
    return {
      accountId: '',
      clientId: '',
      campaignId: '',
      campaigns: null,
      keywords: null,
      selectedAdzCampaign: null,
      selectedAdzGroup: null,
      selectedAdzGroupSlug: null,
      defaultAllCampaign: null,
      isShareLink: false,
      showCommentThread: false,
      commentsThread: null,
      currentMarker: null,
      currentMarkerAdz: null,
      markerIsEmpty: null,
      userCommentsHistory: '',
      isLoading: true,
    }
  },
  computed: {
    agregateAdgroups() {
      const reducer = (accumulator, currentValue) => {
        accumulator = [...accumulator, ...currentValue.adGroups]
        return accumulator
      }
      const agregateAdgroups = Array.from(new Set(this.campaigns.reduce(reducer, [])))
      return agregateAdgroups
    },
    agregateKeywords() {
      let agregateKeywords = []

      if (!this.selectedAdzCampaign && !this.selectedAdzGroup) {
        const reducer = (accumulator, currentValue) => {
          if (currentValue.keywords) {
            for (const i of Object.keys(currentValue.keywords)) {
              accumulator = [...accumulator, ...currentValue.keywords[i]]
            }
          }
          return accumulator
        }

        agregateKeywords = this.campaigns.reduce(reducer, [])
      } else if (!this.selectedAdzCampaign && this.selectedAdzGroup) {
        const campaignReducer = (accumulator, currentValue) => {
          if (this.campaigns[currentValue].adGroups && this.campaigns[currentValue].adGroups.includes(this.selectedAdzGroup)) {
            accumulator = [...accumulator, this.campaigns[currentValue]]
          }
          return accumulator
        }

        const adzGroupCampaign = Object.keys(this.campaigns).reduce(campaignReducer, [])

        const reducer = (accumulator, currentValue) => {
          if (currentValue.keywords) {
            for (const i of Object.keys(currentValue.keywords)) {
              accumulator = [...accumulator, ...currentValue.keywords[i]]
            }
          }
          return accumulator
        }

        agregateKeywords = adzGroupCampaign.reduce(reducer, [])
      } else if (this.selectedAdzCampaign && this.selectedAdzCampaign.keywords && !this.selectedAdzGroup) {
        if (!this.selectedAdzCampaign.keywords) {
          return []
        }

        // console.log(Object.keys(this.selectedAdzCampaign.keywords))

        const reducer = (accumulator, currentValue) => {
          accumulator = [...accumulator, ...this.selectedAdzCampaign.keywords[currentValue]]
          return accumulator
        }

        agregateKeywords = Object.keys(this.selectedAdzCampaign.keywords).reduce(reducer, [])
      } else if (this.selectedAdzCampaign && this.selectedAdzCampaign.keywords && this.selectedAdzGroup) {
        if (!this.selectedAdzCampaign.keywords) {
          return []
        }

        // console.log(Object.keys(this.selectedAdzCampaign.keywords))

        const reducer = (accumulator, currentValue) => {
          if (currentValue.trim() === this.selectedAdzGroup.trim()) {
            accumulator = [...accumulator, ...this.selectedAdzCampaign.keywords[currentValue]]
          }
          return accumulator
        }

        agregateKeywords = Object.keys(this.selectedAdzCampaign.keywords).reduce(reducer, [])
      }

      return agregateKeywords
    },
    agregateKeywordsAndType() {
      let agregateKeywords = []

      // console.log('GO')

      if (!this.selectedAdzCampaign && !this.selectedAdzGroup) {
        // console.log('WOW')

        const reducer = (accumulator, currentValue) => {
          if (currentValue.keywordsAndType) {
            for (const i of Object.keys(currentValue.keywordsAndType)) {
              accumulator = [...accumulator, ...currentValue.keywordsAndType[i]]
            }
          }
          return accumulator
        }

        agregateKeywords = this.campaigns.reduce(reducer, [])
      } else if (!this.selectedAdzCampaign && this.selectedAdzGroup) {
        // console.log('WHAT')

        const campaignReducer = (accumulator, currentValue) => {
          if (this.campaigns[currentValue].adGroups && this.campaigns[currentValue].adGroups.includes(this.selectedAdzGroup)) {
            accumulator = [...accumulator, this.campaigns[currentValue]]
          }
          return accumulator
        }

        const adzGroupCampaign = Object.keys(this.campaigns).reduce(campaignReducer, [])

        const reducer = (accumulator, currentValue) => {
          if (currentValue.keywordsAndType) {
            for (const i of Object.keys(currentValue.keywordsAndType)) {
              accumulator = [...accumulator, ...currentValue.keywordsAndType[i]]
            }
          }
          return accumulator
        }

        agregateKeywords = adzGroupCampaign.reduce(reducer, [])
      } else if (this.selectedAdzCampaign && !this.selectedAdzGroup) {
        // console.log('THERE!')
        if (!this.selectedAdzCampaign.keywordsAndType) {
          return []
        }
        // console.log(Object.keys(this.selectedAdzCampaign.keywordsAndType))

        const reducer = (accumulator, currentValue) => {
          accumulator = [...accumulator, ...this.selectedAdzCampaign.keywordsAndType[currentValue]]
          return accumulator
        }

        agregateKeywords = Object.keys(this.selectedAdzCampaign.keywordsAndType).reduce(reducer, [])
      } else if (this.selectedAdzCampaign && this.selectedAdzGroup) {
        // console.log('HERE!')
        if (!this.selectedAdzCampaign.keywordsAndType) {
          return []
        }
        // console.log(Object.keys(this.selectedAdzCampaign.keywordsAndType))

        const reducer = (accumulator, currentValue) => {
          if (currentValue.trim() === this.selectedAdzGroup.trim()) {
            accumulator = [...accumulator, ...this.selectedAdzCampaign.keywordsAndType[currentValue]]
          }
          return accumulator
        }

        agregateKeywords = Object.keys(this.selectedAdzCampaign.keywordsAndType).reduce(reducer, [])
      }

      return agregateKeywords
    },
    ...mapState('app', ['appTitle', 'appDomain', 'platforms', 'commentsToggle']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'selectedClient', 'selectedCampaign']),
  },
  watch: {
    $route: {
      async handler($route) {
        if (!isNil($route) && $route.name === 'share-link-google') {
          // console.log('View:: share-link-google')
          this.isShareLink = true

          // Reset any share adz coming from Facebook and other platforms...
          this.setShareAdzList(null)
          this.selectAdzToShare(false)

          // Refacto weird bug
          setTimeout(async () => {
            const shareLinksGoogleDBRef = new ShareLinksGoogleDB()
            const shareLinkObj = await shareLinksGoogleDBRef.read($route.query.slid)

            const { accountId, clientId, campaignId } = shareLinkObj

            this.accountId = accountId
            this.clientId = clientId
            this.campaignId = campaignId

            this.selectAccount(accountId)
            this.selectClient(clientId, accountId)
            this.selectCampaign({
              clientId: clientId,
              campaignId: campaignId,
              accountId: accountId,
            })

            const fetchShareLinkGoogle = functions.httpsCallable('fetchShareLinkGoogle')

            // console.log('fetchCampaigns')

            let fetchCampaigns = await fetchShareLinkGoogle({ shareLinkId: $route.query.slid })

            if (!fetchCampaigns || !fetchCampaigns.data) {
              // console.log('There was an error loading your adz...')
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }

            fetchCampaigns = fetchCampaigns.data

            // We don't need to Show the Campaign 'All' as it is a 'Default placeholder'
            this.defaultAllCampaign = fetchCampaigns.filter((item) => item.name === 'All')[0]
            this.campaigns = fetchCampaigns.filter((item) => item.name !== 'All')

            this.isLoading = false
          }, 3500)
        } else if (!isNil($route) && $route.name === 'share-link-google-dynamic') {
          // Use new route
          this.$router.push({ name: 'share-link-google', query: { slid: $route.params.shareLinkId } })
        }
      },
      immediate: true,
    },
    account: {
      async handler(account) {
        if (!isNil(account) && this.$route.name !== 'share-link-google') {
          // console.log('View:: google')
          this.clientId = this.$route.params.clientId
          this.campaignId = this.$route.params.campaignId

          this.selectClient(this.$route.params.clientId)
          this.selectCampaign({
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          })

          this.refreshGoogleAdzView()

          this.isLoading = false
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.userCommentsHistory = localStorage.getItem('userCommentsHistory') ? localStorage.getItem('userCommentsHistory') : ''

    document.querySelector('body').addEventListener('click', () => {
      this.toggleCommentsPopupVisibility(false) // Force Close
    })
  },
  methods: {
    selectAdzCampaign(campaign) {
      // Unselect any AdzGroup
      this.selectedAdzGroup = null
      this.selectedAdzGroupSlug = null

      if (this.selectedAdzCampaign === campaign) {
        this.selectedAdzCampaign = null
        return
      }

      this.selectedAdzCampaign = campaign
    },
    selectAdzGroup(adzGroup) {
      if (this.selectedAdzGroup === adzGroup) {
        this.selectedAdzGroup = null
        this.selectedAdzGroupSlug = null
        return
      }

      this.selectedAdzGroup = adzGroup
      this.selectedAdzGroupSlug = adzGroup.trim()
    },
    async handleCreateMarker(e, slide) {
      e.preventDefault()

      if (!this.commentsToggle) {
        return
      }

      if (this.showCommentThread) {
        this.toggleCommentsPopupVisibility(false)
        return
      }

      const target = e.target.closest('.markers-box-wrap')
      const { percentX, percentY } = getPercentFromZone(e, target)

      const accountClientCampaignGAdzDbRef = new AccountClientCampaignGoogleAdzDB(
        this.account[0].id,
        this.$route.params.clientId,
        this.$route.params.campaignId
      )
      const currentAdz = await accountClientCampaignGAdzDbRef.read(slide.id)
      const currentAdzGlobalMeta = await accountClientCampaignGAdzDbRef.read('All')
      const markerNumber = currentAdzGlobalMeta.markerCurrentIndex ? currentAdzGlobalMeta.markerCurrentIndex : 1

      const newMarker = {
        number: markerNumber,
        posX: percentX,
        posY: percentY,
        id: `${new Date().getTime()}`,
        type: 'google',
        isHeader: false,
        isFooter: false,
      }

      const updatedAdz = { ...currentAdz }
      const updatedAdzGlobalMeta = { ...currentAdzGlobalMeta }

      updatedAdz['commentMarkers'] = updatedAdz['commentMarkers'] ? updatedAdz['commentMarkers'] : []
      updatedAdz['commentMarkers'].push(newMarker)

      updatedAdzGlobalMeta.markerCurrentIndex = markerNumber + 1

      await accountClientCampaignGAdzDbRef.update(updatedAdz)
      await accountClientCampaignGAdzDbRef.update(updatedAdzGlobalMeta)

      this.refreshGoogleAdzView()

      setTimeout(() => {
        this.currentMarker = newMarker
        this.currentMarkerAdz = currentAdz
        this.markerIsEmpty = newMarker
        this.handleMarkerClick({ clientX: null, clientY: null }, this.currentMarker, this.currentMarkerAdz, e.clientX, e.clientY)
      }, 15)
    },
    async handleMarkerClick(e, marker, markerAdz, usePosX = null, usePosY = null) {
      // console.log('handleMarkerClick', this.showCommentThread)
      // Load marker comments thread
      if (this.showCommentThread) {
        this.toggleCommentsPopupVisibility(false) // Force Close
        return
      }

      this.currentMarker = marker
      this.currentMarkerAdz = markerAdz

      positionCommentBox(e, usePosX, usePosY)
      this.toggleCommentsPopupVisibility(true) // Force Open
      this.refreshCurrentCommentsThread()

      // User has seen this maker's commentsThrea
      const userCommentsHistory = localStorage.getItem('userCommentsHistory') ? localStorage.getItem('userCommentsHistory') : ''
      let updatedHistory = ''
      if (!userCommentsHistory) {
        updatedHistory = marker.id
      } else {
        updatedHistory = userCommentsHistory.includes(marker.id) ? userCommentsHistory : userCommentsHistory + ', ' + marker.id
      }
      localStorage.setItem('userCommentsHistory', updatedHistory)
      this.userCommentsHistory = localStorage.getItem('userCommentsHistory')
    },
    async willResolveCurrentMarker() {
      // console.log(this.currentMarker, this.currentMarker.id, this.currentMarkerAdz)

      this.$swal({
        title: 'Resolve',
        text: `This comment will be closed and deleted.`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/archive.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          this.resolveCurrentMaker()
        }
      })
    },
    async resolveCurrentMaker(removeFromCounter = false) {
      // console.log(this.currentMarkerAdz)

      if (!this.currentMarkerAdz) {
        return
      }

      const accountClientCampaignGAdzDbRef = new AccountClientCampaignGoogleAdzDB(
        this.account[0].id,
        this.$route.params.clientId,
        this.$route.params.campaignId
      )
      const currentAdz = await accountClientCampaignGAdzDbRef.read(this.currentMarkerAdz.id)
      const currentAdzGlobalMeta = await accountClientCampaignGAdzDbRef.read('All')
      const indexOfMarker = currentAdz.commentMarkers.findIndex((m) => m.id === this.currentMarker.id)
      currentAdz.commentMarkers.splice(indexOfMarker, 1)

      let counter = currentAdzGlobalMeta.markerCurrentIndex
      if (removeFromCounter) {
        counter = counter - 1
      }
      const updatedAdz = { ...currentAdz }
      const updatedAdzGlobalMeta = { ...currentAdzGlobalMeta, markerCurrentIndex: counter }

      await accountClientCampaignGAdzDbRef.update(updatedAdz)
      await accountClientCampaignGAdzDbRef.update(updatedAdzGlobalMeta)

      this.refreshGoogleAdzView()

      const CommentsThreadDBRef = new CommentsThreadDB()
      await CommentsThreadDBRef.delete(this.currentMarker.id)

      this.currentMarker = null
      this.currentMarkerAdz = null
      this.commentsThread = null
      this.markerIsEmpty = null

      this.toggleCommentsPopupVisibility(false)
    },
    async refreshCurrentCommentsThread() {
      const CommentsThreadDBRef = new CommentsThreadDB()
      const commentsThread = await CommentsThreadDBRef.read(this.currentMarker.id)

      this.commentsThread = commentsThread && commentsThread.comments.length ? commentsThread.comments : []

      // Does this marker has comments or is empty ?
      this.markerIsEmpty = this.commentsThread.length ? null : this.currentMarker
    },
    toggleCommentsPopupVisibility(forceValue = 'none') {
      // console.log('toggleCommentsPopupVisibility', forceValue)
      // Always start fresh on current commentsThread
      this.commentsThread = null

      if (forceValue !== 'none') {
        this.showCommentThread = forceValue
        if (forceValue === false && this.markerIsEmpty) {
          this.deleteEmptyMarker()
        }
        return
      }

      if (this.showCommentThread) {
        this.showCommentThread = false
        if (this.markerIsEmpty) {
          this.deleteEmptyMarker()
        }
      } else {
        this.showCommentThread = true
      }
    },
    deleteEmptyMarker() {
      this.currentMarker = this.markerIsEmpty
      this.resolveCurrentMaker(true)
    },
    async refreshGoogleAdzView() {
      const accountClientCampaignGoogleAdzDBRef = new AccountClientCampaignGoogleAdzDB(
        this.account[0].id,
        this.$route.params.clientId,
        this.$route.params.campaignId
      )

      const fetchCampaigns = await accountClientCampaignGoogleAdzDBRef.readAll(null, false, 'name', 'asc')

      // We don't need to Show the Campaign 'All' as it is a 'Default placeholder'
      this.defaultAllCampaign = fetchCampaigns.filter((item) => item.name === 'All')[0]
      this.campaigns = fetchCampaigns.filter((item) => item.name !== 'All')

      if (this.$route.query.selectCampaign) {
        // console.log(this.$route.query.selectCampaign)
        this.selectedAdzCampaign = fetchCampaigns.filter((item) => {
          return item.name === this.$route.query.selectCampaign
        })[0]
      }
    },
    ...mapActions('app', ['selectAdzToShare']),
    ...mapActions('users', ['selectAccount', 'selectClient', 'selectCampaign', 'setShareAdzList']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';

.google-ads-main-wrap {
  font-family: arial, sans-serif;
}

.google-ads-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    display: flex;
    align-items: center;
  }

  .logo {
    width: 92px;
    height: 30px;
    margin-right: 27px;
  }

  .search {
    width: 640px;
    height: 44px;
    padding: 10px 20px;
    background-color: #fff;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    -webkit-appearance: none;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-family: arial, sans-serif;
    background-image: url(../../assets/img/google-search.jpg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    background-size: 55px;
  }

  .dots {
    width: 16px;
    height: 16px;
    margin-right: 22px;
  }

  .import-google-ads {
    color: $purple-blue;
    margin-right: 22px;
    font-size: 14px;
  }

  .profile {
    height: 32px;
    width: 32px;
    border-radius: 30px;
  }

  @media screen and (max-width: 1024px) {
    position: relative;
    padding: 20px 15px;

    .search {
      display: none;
    }
    .dots {
      display: none;
    }
    .mobile-search-bar {
      display: block;
    }
    .show-filters {
      display: block;
    }
  }
}

.content-ads-wrap {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 40px 40px 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .campaings-wrap {
    width: 400px;
    margin-left: 2vw;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    color: $dark-grey;

    @media screen and (max-width: 1024px) {
      position: absolute;
      top: 125px;
      left: -100%;
      background-color: #fff;
      width: 100%;
      margin-left: 0;
      z-index: 10000000000;
      border-radius: 0;
      border: 0;
      &.active {
        left: 0;
      }
    }

    .section-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      padding: 1.2vw;

      svg {
        margin-right: 10px;
        opacity: 0.3;
        transition: all 0.4s ease;
      }

      p {
        font-size: 16px;
        color: $dark-blue;
        opacity: 0.3;
        transition: all 0.4s ease;
      }

      &.show {
        svg,
        p {
          opacity: 1;
        }
      }
    }

    .ad-groups-wrap {
      height: 0;
      overflow: hidden;
      transition: all 0.4s ease;

      &.show {
        height: auto;
      }
    }

    .data-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      padding: 12px 17px;
      cursor: pointer;

      &:hover,
      &.actif {
        background-color: #f3f3f3;
      }

      &.off {
        display: none;
      }

      p {
        font-size: 14px;
        color: $dark-grey;
      }
    }

    .keywords-wrap {
      padding: 1.2vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      .keyword {
        background-color: #f9f9fb;
        border: 1px solid #ebebeb;
        padding: 5px 10px;
        font-size: 14px;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 20px;

        &.add-keyword {
          background: linear-gradient(to right, #2e7df2, #8a23f1);
          color: #fff;
          cursor: pointer;
        }

        &.Negative-Broad-match,
        &.Negative-Exact-match,
        &.Negative-Phrase-match {
          background: #f9c1bd;
          cursor: default;
          order: 99;
        }
      }
    }
  }

  .ads-wrap {
    width: 630px;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 12px;

    .single-ad-wrap {
      background-color: #fff;
      padding: 20px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      margin-bottom: 12px;

      .link-style {
        font-size: 18px;
      }

      .bottom-space {
        padding-bottom: 0;
      }

      .Sitelinks {
        padding: 0;
        margin-top: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .Sitelink {
          width: 100%;
          margin-bottom: 0;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          padding: 8px 0;

          p {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 570px) {
    padding: 20px 20px 80px 20px;
  }
}

.main-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(5);
  z-index: 1000;
}

.pastille {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: auto;
  z-index: 1000;
}

.loading-label {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100px);
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}

.edit-google-ad-panel {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000000;
  width: 42vw;
  height: 100vh;
  background-color: #fff;
  border-left: 1px solid #ebebeb;
  padding: 2vw;
  overflow-y: scroll;

  .close {
    position: absolute;
    top: 2vw;
    right: 2vw;
    width: 15px;
    cursor: pointer;
  }

  .edit-google-ad-form {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: $dark-grey;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .section-title {
      font-weight: 600;
      font-size: 14px;
      color: $dark-blue;
      margin-bottom: 15px;
      margin-top: 20px;

      &.first {
        margin-top: 0;
      }
    }

    .field-wrap {
      width: 100%;
      &.half {
        width: calc(50% - 5px);
      }
      .counter {
        bottom: 25px;
        font-size: 13px;
      }
    }

    .input-label-wrap {
      margin-bottom: 10px;
      width: 100%;

      &.half {
        width: calc(50% - 5px);
      }

      .label {
        font-size: 13px;
      }

      .label-focus:focus + .label {
        font-size: 11px;
        transform: translate(15px, -120%);
        color: $purple-blue;
      }

      .label-focus:focus {
        padding: 30px 15px 15px 15px;
      }
    }

    input[type='text'] {
      height: 50px;
      font-size: 13px;
    }
  }
}
</style>
