<template>
  <div :data-ad-campaign="campaignObj.name" class="ad-wrap">
    <div class="markers-box-wrap" @click="handleCreateMarker($event, campaignObj)">
      <div v-for="adGroupVal in campaignObj.adGroups" :key="adGroupVal.trim()" :data-ad-group="adGroupVal.trim()">
        <div
          v-if="
            (campaignObj.annonces && campaignObj.annonces[adGroupVal.trim()] && !selectedAdzCampaign && !selectedAdzGroup) ||
            (campaignObj.annonces &&
              campaignObj.annonces[adGroupVal.trim()] &&
              selectedAdzCampaign &&
              selectedAdzCampaign.name === campaignObj.name &&
              !selectedAdzGroup) ||
            (campaignObj.annonces && campaignObj.annonces[adGroupVal.trim()] && !selectedAdzCampaign && selectedAdzGroup.trim() === adGroupVal.trim()) ||
            (campaignObj.annonces &&
              campaignObj.annonces[adGroupVal.trim()] &&
              selectedAdzCampaign &&
              selectedAdzCampaign.name === campaignObj.name &&
              selectedAdzGroup.trim() === adGroupVal.trim())
          "
        >
          <div
            v-for="(item, index) in campaignObj.annonces[adGroupVal.trim()]"
            :key="index"
            :data-ad-campaign="campaignObj.name"
            :data-ad-group="adGroupVal.trim()"
            :data-ad-key="index"
            class="single-ad-wrap"
          >
            <!-- <p class="debug-line">
              <small>Debug: {{ campaignObj.name }} // {{ adGroupVal.trim() }} // {{ index }}</small>
            </p> -->
            <header>
              <p class="bold">Ad</p>
              <p class="bold spacer">·</p>

              <!-- Display URL -->
              <p v-if="item['Final URL'] && item['Final URL'].hyperlink" class="light url">
                <span>{{ item['Final URL'].hyperlink }}</span>
                <span v-if="item['Path 1']"
                  >{{ item['Final URL'].hyperlink && item['Final URL'].hyperlink.charAt(item['Final URL'].hyperlink.length - 1) === '/' ? '' : '/'
                  }}{{ item['Path 1'] }}</span
                >
                <span v-if="item['Path 2']">/{{ item['Path 2'] }}</span>
              </p>
              <p v-else-if="item['Final URL'] && !item['Final URL'].hyperlink" class="light url">
                <span>{{ item['Final URL'] }}</span>
                <span v-if="item['Path 1']"
                  >{{ item['Final URL'].hyperlink && item['Final URL'].hyperlink.charAt(item['Final URL'].hyperlink.length - 1) === '/' ? '' : '/'
                  }}{{ item['Path 1'] }}</span
                >
                <span v-if="item['Path 2']">/{{ item['Path 2'] }}</span>
              </p>
              <p v-else class="light">[Please provide a valid URL]</p>

              <!-- Call Extension : Campaing - AdGroup -->
              <p v-if="campaignObj.callExtension && campaignObj.callExtension[adGroupVal.trim()]" class="light phone">
                {{ campaignObj.callExtension[adGroupVal.trim()][0]['Phone #'] }}
              </p>
              <!-- Call Extension : Campaing - All -->
              <p v-else-if="campaignObj.callExtension && campaignObj.callExtension['All']" class="light phone">
                {{ campaignObj.callExtension['All'][0]['Phone #'] }}
              </p>
              <!-- Call Extension : All - AdGroup -->
              <p
                v-else-if="defaultAllCampaign && defaultAllCampaign.callExtension && defaultAllCampaign.callExtension[adGroupVal.trim()]"
                class="light phone"
              >
                {{ defaultAllCampaign.callExtension[adGroupVal.trim()][0]['Phone #'] }}
              </p>
              <!-- Call Extension : All - All -->
              <p v-else-if="defaultAllCampaign && defaultAllCampaign.callExtension && defaultAllCampaign.callExtension['All']" class="light phone">
                {{ defaultAllCampaign.callExtension['All'][0]['Phone #'] }}
              </p>
            </header>

            <!-- Headline with Link -->
            <a
              v-if="item['Final URL'] && item['Final URL'].hyperlink"
              :href="item['Final URL'].hyperlink"
              class="main-title link-style"
              target="_blank"
              rel="noopener noreferer"
              @click="goToLinkIfNotCommentsMode(item['Final URL'].hyperlink)"
            >
              {{ item['Headline 1'] }} | {{ item['Headline 2'] }} <span v-if="item['Headline 3']">|</span>
              {{ item['Headline 3'] }}
            </a>
            <a
              v-else-if="item['Final URL'] && !item['Final URL'].hyperlink"
              :href="item['Final URL']"
              class="main-title link-style"
              target="_blank"
              rel="noopener noreferer"
              @click="goToLinkIfNotCommentsMode(item['Final URL'])"
            >
              {{ item['Headline 1'] }} | {{ item['Headline 2'] }} <span v-if="item['Headline 3']">|</span>
              {{ item['Headline 3'] }}
            </a>
            <a v-else href="#" class="main-title link-style">
              {{ item['Headline 1'] }} | {{ item['Headline 2'] }} <span v-if="item['Headline 3']">|</span>
              {{ item['Headline 3'] }}
            </a>

            <!-- Description -->
            <p class="description text-style">{{ item['Description 1'] }} {{ item['Description 2'] }}&nbsp;</p>

            <!-- Callouts : Campaing - AdGroup -->
            <div v-if="campaignObj.callouts && campaignObj.callouts[adGroupVal.trim()]" class="callouts">
              <p class="callout-txt">{{ campaignObj.callouts[adGroupVal.trim()][0]['Callout 1'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ campaignObj.callouts[adGroupVal.trim()][0]['Callout 2'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ campaignObj.callouts[adGroupVal.trim()][0]['Callout 3'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ campaignObj.callouts[adGroupVal.trim()][0]['Callout 4'] }}</p>
            </div>
            <!-- Callouts : Campaing - All -->
            <div v-else-if="campaignObj.callouts && campaignObj.callouts['All']" class="callouts">
              <p class="callout-txt">{{ campaignObj.callouts['All'][0]['Callout 1'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ campaignObj.callouts['All'][0]['Callout 2'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ campaignObj.callouts['All'][0]['Callout 3'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ campaignObj.callouts['All'][0]['Callout 4'] }}</p>
            </div>
            <!-- Callouts : All - AdGroup -->
            <div v-else-if="defaultAllCampaign && defaultAllCampaign.callouts && defaultAllCampaign.callouts[adGroupVal.trim()]" class="callouts">
              <p class="callout-txt">{{ defaultAllCampaign.callouts[adGroupVal.trim()][0]['Callout 1'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ defaultAllCampaign.callouts[adGroupVal.trim()][0]['Callout 2'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ defaultAllCampaign.callouts[adGroupVal.trim()][0]['Callout 3'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ defaultAllCampaign.callouts[adGroupVal.trim()][0]['Callout 4'] }}</p>
            </div>
            <!-- Callouts : All - All -->
            <div
              v-else-if="defaultAllCampaign && defaultAllCampaign.callouts && defaultAllCampaign.callouts['All']"
              class="callouts"
              :data-adgroup="index"
            >
              <p class="callout-txt">{{ defaultAllCampaign.callouts['All'][0]['Callout 1'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ defaultAllCampaign.callouts['All'][0]['Callout 2'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ defaultAllCampaign.callouts['All'][0]['Callout 3'] }}</p>
              <p class="spacer">·</p>

              <p class="callout-txt">{{ defaultAllCampaign.callouts['All'][0]['Callout 4'] }}</p>
            </div>

            <!-- Structured Snippets : Campaing - AdGroup -->
            <div v-if="campaignObj.structuredSnippets && campaignObj.structuredSnippets[adGroupVal.trim()]" class="structured-snippets">
              <p class="text-style">{{ campaignObj.structuredSnippets[adGroupVal.trim()][0]['Header'] }}:</p>
              <p class="text-style space-left">
                {{ campaignObj.structuredSnippets[adGroupVal.trim()][0]['Value 1'] }}
              </p>
              <p v-if="campaignObj.structuredSnippets[adGroupVal.trim()][0]['Value 2']" class="text-style">,</p>
              <p class="text-style space-left">
                {{ campaignObj.structuredSnippets[adGroupVal.trim()][0]['Value 2'] }}
              </p>
              <p v-if="campaignObj.structuredSnippets[adGroupVal.trim()][0]['Value 3']" class="text-style">,</p>
              <p class="text-style space-left">
                {{ campaignObj.structuredSnippets[adGroupVal.trim()][0]['Value 3'] }}
              </p>
            </div>

            <!-- Structured Snippets : Campaing - All -->
            <div v-else-if="campaignObj.structuredSnippets && campaignObj.structuredSnippets['All']" class="structured-snippets">
              <p class="text-style">{{ campaignObj.structuredSnippets['All'][0]['Header'] }}:</p>
              <p class="text-style space-left">{{ campaignObj.structuredSnippets['All'][0]['Value 1'] }}</p>
              <p v-if="campaignObj.structuredSnippets['All'][0]['Value 2']" class="text-style">,</p>
              <p class="text-style space-left">{{ campaignObj.structuredSnippets['All'][0]['Value 2'] }}</p>
              <p v-if="campaignObj.structuredSnippets['All'][0]['Value 3']" class="text-style">,</p>
              <p class="text-style space-left">{{ campaignObj.structuredSnippets['All'][0]['Value 3'] }}</p>
            </div>

            <!-- Structured Snippets : All - AdGroup -->
            <div
              v-else-if="defaultAllCampaign && defaultAllCampaign.structuredSnippets && defaultAllCampaign.structuredSnippets[adGroupVal.trim()]"
              class="structured-snippets"
            >
              <p class="text-style">{{ defaultAllCampaign.structuredSnippets[adGroupVal.trim()][0]['Header'] }}:</p>
              <p class="text-style space-left">
                {{ defaultAllCampaign.structuredSnippets[adGroupVal.trim()][0]['Value 1'] }}
              </p>
              <p v-if="defaultAllCampaign.structuredSnippets[adGroupVal.trim()][0]['Value 2']" class="text-style">,</p>
              <p class="text-style space-left">
                {{ defaultAllCampaign.structuredSnippets[adGroupVal.trim()][0]['Value 2'] }}
              </p>
              <p v-if="defaultAllCampaign.structuredSnippets[adGroupVal.trim()][0]['Value 3']" class="text-style">,</p>
              <p class="text-style space-left">
                {{ defaultAllCampaign.structuredSnippets[adGroupVal.trim()][0]['Value 3'] }}
              </p>
            </div>

            <!-- Structured Snippets : All - All -->
            <div
              v-else-if="defaultAllCampaign && defaultAllCampaign.structuredSnippets && defaultAllCampaign.structuredSnippets['All']"
              class="structured-snippets"
            >
              <p class="text-style">{{ defaultAllCampaign.structuredSnippets['All'][0]['Header'] }}:</p>
              <p class="text-style space-left">
                {{ defaultAllCampaign.structuredSnippets['All'][0]['Value 1'] }}
              </p>
              <p v-if="defaultAllCampaign.structuredSnippets['All'][0]['Value 2']" class="text-style">,</p>
              <p class="text-style space-left">
                {{ defaultAllCampaign.structuredSnippets['All'][0]['Value 2'] }}
              </p>
              <p v-if="defaultAllCampaign.structuredSnippets['All'][0]['Value 3']" class="text-style">,</p>
              <p class="text-style space-left">
                {{ defaultAllCampaign.structuredSnippets['All'][0]['Value 3'] }}
              </p>
            </div>

            <!-- SiteLinks : Campaing - AdGroup -->
            <div v-if="campaignObj.sitelinks && campaignObj.sitelinks[adGroupVal.trim()]" class="Sitelinks">
              <div v-for="(itemA, indexA) in campaignObj.sitelinks[adGroupVal.trim()]" :key="indexA" class="Sitelink">
                <a
                  :href="itemA['Sitelink URL']"
                  class="link-style"
                  target="_blank"
                  rel="noopener noreferer"
                  @click="goToLinkIfNotCommentsMode(itemA['Sitelink URL'])"
                  >{{ itemA['Sitelink title'] }}</a
                >
                <p class="text-style">
                  {{ itemA['Sitelink description 1'] }}
                  {{ itemA['Sitelink description 2'] }}
                </p>
              </div>
            </div>
            <!-- SiteLinks : Campaign - All -->
            <div v-else-if="campaignObj.sitelinks && campaignObj.sitelinks['All']" class="Sitelinks">
              <div v-for="(itemB, indexB) in campaignObj.sitelinks['All']" :key="indexB" class="Sitelink">
                <a
                  :href="itemB['Sitelink URL']"
                  class="link-style"
                  target="_blank"
                  rel="noopener noreferer"
                  @click="goToLinkIfNotCommentsMode(itemB['Sitelink URL'])"
                  >{{ itemB['Sitelink title'] }}</a
                >
                <p class="text-style">
                  {{ itemB['Sitelink description 1'] }}
                  {{ itemB['Sitelink description 2'] }}
                </p>
              </div>
            </div>
            <!-- SiteLinks : All - AdGroup -->
            <div v-else-if="defaultAllCampaign && defaultAllCampaign.sitelinks && defaultAllCampaign.sitelinks[adGroupVal.trim()]" class="Sitelinks">
              <div v-for="(itemC, indexC) in defaultAllCampaign.sitelinks[adGroupVal.trim()]" :key="indexC" class="Sitelink">
                <a
                  :href="itemC['Sitelink URL']"
                  class="link-style"
                  target="_blank"
                  rel="noopener noreferer"
                  @click="goToLinkIfNotCommentsMode(itemC['Sitelink URL'])"
                  >{{ itemC['Sitelink title'] }}</a
                >
                <p class="text-style">
                  {{ itemC['Sitelink description 1'] }}
                  {{ itemC['Sitelink description 2'] }}
                </p>
              </div>
            </div>
            <!-- SiteLinks : All - All -->
            <div v-else-if="defaultAllCampaign && defaultAllCampaign.sitelinks && defaultAllCampaign.sitelinks['All']" class="Sitelinks">
              <div v-for="(itemD, indexD) in defaultAllCampaign.sitelinks['All']" :key="indexD" class="Sitelink">
                <a
                  :href="itemD['Sitelink URL']"
                  class="link-style"
                  target="_blank"
                  rel="noopener noreferer"
                  @click="goToLinkIfNotCommentsMode(itemD['Sitelink URL'])"
                  >{{ itemD['Sitelink title'] }}</a
                >
                <p class="text-style">
                  {{ itemD['Sitelink description 1'] }}
                  {{ itemD['Sitelink description 2'] }}
                </p>
              </div>
            </div>

            <img alt="Show icon" class="edit-google-ad" src="@/assets/img/edit-icon.svg" />
          </div>
        </div>
      </div>

      <!-- Comments Markers -->
      <div v-if="commentsToggle && campaignObj.commentMarkers && campaignObj.commentMarkers.length">
        <div
          v-for="(marker, index) in campaignObj.commentMarkers"
          :key="index"
          class="comment-marker"
          :class="{ new: !userCommentsHistory.includes(marker.id) }"
          :style="[{ left: `${marker.posX}%`, top: `${marker.posY}%` }]"
          @click.prevent.stop="handleMarkerClick($event, marker, campaignObj)"
        >
          <p>{{ marker.number ? marker.number : 1 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaignObj: {
      type: Object,
      default: null,
    },
    selectedAdzCampaign: {
      type: Object,
      default: null,
    },
    selectedAdzGroup: {
      type: String,
      default: null,
    },
    selectedAdzGroupSlug: {
      type: String,
      default: null,
    },
    defaultAllCampaign: {
      type: Object,
      default: null,
    },
    commentsToggle: {
      type: Boolean,
      default: false,
    },
    userCommentsHistory: {
      type: String,
      default: '',
    },
    handleCreateMarker: {
      type: Function,
      required: true,
    },
    handleMarkerClick: {
      type: Function,
      required: true,
    },
  },
  methods: {
    goToLinkIfNotCommentsMode(link) {
      if (this.commentsToggle) {
        return
      }
      window.open(link, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/comments.scss';

.ad-wrap {
  width: 100%;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;

  .single-ad-wrap {
    position: relative;
    padding-bottom: 25px;
    padding-left: 40px;
    transition: all 0.4s ease;

    .description {
      display: inline;
    }

    .edit-google-ad {
      width: 18px;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.2s ease;
      opacity: 0;
      cursor: pointer;
    }

    // &:hover {
    //   .edit-google-ad {
    //     left: 8px;
    //     opacity: 1;
    //   }
    // }

    @media screen and (max-width: 570px) {
      padding-left: 0;
    }
  }

  .unique-ad-wrap {
    padding-bottom: 10px;
  }

  .options-pop {
    right: 0;
    left: 0;
    top: inherit;
    bottom: 20px;
    display: flex;
    justify-content: flex-start;
  }

  .icon-wrap {
    position: relative;
    top: -20px;
    margin-left: 0;
    width: 30px;
    height: 30px;
    background-color: $dark-grey;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    opacity: 0;

    &.show1 {
      transition: top 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }
    &.show2 {
      transition: top 0.4s ease-in-out, opacity 0.4s ease-in-out;
    }
    &.show3 {
      transition: top 0.6s ease-in-out, opacity 0.6s ease-in-out;
    }

    &:hover {
      background-color: #4628ff;
      .tool-tip {
        top: -33px;
      }
    }

    .tool-tip {
      top: -45px;
    }

    svg {
      transform: scale(0.7, 0.7);
      path {
        fill: #fff;
      }
    }
  }

  .link-style {
    font-weight: 400;
    font-size: 20px;
    color: #1a0dab;
    text-decoration: none;
    line-height: 26px;

    &:hover {
      text-decoration: underline;
    }
  }

  .text-style {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #3c4043;
  }

  header {
    display: flex;
    margin-bottom: 13px;

    .url {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
    }

    .bold {
      font-weight: 700;
      font-size: 14px;
      color: #202124;
      &.spacer {
        margin: 0 5px;
      }
    }

    .light {
      font-weight: 400;
      font-size: 14px;
      color: #3c4043;
    }

    .phone {
      margin-left: 10px;
    }
  }

  .main-title {
    margin-bottom: 3px;
    display: block;
  }

  .callouts {
    margin-top: 5px;

    p {
      color: #4d5156;
      font-size: 14px;
    }

    .callout-txt {
      display: inline;
    }

    .spacer {
      color: #4d5156;
      font-size: 14px;
      margin: 0 5px;
      display: inline-block;
    }
  }

  .structured-snippets {
    display: flex;
    margin-top: 5px;

    .space-left {
      margin-left: 5px;
    }
  }

  .Sitelinks {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;

    .Sitelink {
      width: calc(45% - 10px);
      margin-bottom: 5px;
      padding: 10px 15px;
      cursor: pointer;
      transition: all 0.2s ease;

      .link-style {
        font-size: 20px;
      }

      &:hover {
        a {
          text-decoration: underline;
        }
      }
    }
    @media screen and (max-width: 600px) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 0;
      margin-top: 18px;
      .Sitelink {
        width: 100%;
        border: 0;
        border-radius: 0;
        padding: 15px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 0;

        .link-style {
          font-size: 18px;
        }

        p {
          display: none;
        }
      }
    }
  }
}

.markers-box-wrap {
  // background: rgba(red, 0.123123);
  // border: 1px solid rgba(red, 0.123123);
  // margin-bottom: 50px;
}
</style>
